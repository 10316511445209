//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal, { ModalMixin } from "@/components/modals/layouts/Large.vue";
import Wrapper from "./Wrapper";
export default {
  mixins: [ModalMixin],
  data() {
    return {};
  },
  methods: {
    onOpen() {}
  },
  components: {
    Modal,
    Wrapper
  }
};